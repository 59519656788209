<template>
  <div class="fittings">
    <div class="fittings-filter" >
      <el-row type="flex" align="middle">
        <el-col class="fittings-filter-title" :span="2">仓储备件</el-col>
        <el-col class="fittings-filter-cond input" :span="9" :offset="3">
          <el-input
            placeholder="输入备件名称"
            size="large"
            enter-button="搜索"></el-input>
        </el-col>

        <el-col :span="3">
          <el-select
            class="fittings-filter-select"
            show-search
            :value="value"
            placeholder="选取机型"
            size="large"
            :default-active-first-option="false"
            :show-arrow="true"
            :filter-option="false"
            :not-found-content="null"
          >
            <el-option v-for="d in data" :key="d.value">
              {{ d.text }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" :offset="1">
          <el-button type="text">
            <i class="iconfont iconqingdan1"></i>
            配件申请
          </el-button>
          <el-button type="text">
            <i class="iconfont icondaishouhuo"></i>
            收件管理
          </el-button>
        </el-col>
      </el-row>
      <el-tabs>
        <el-tab-pane :key="idx+''" :label="tab" v-for="(tab, idx) in tabs">
        </el-tab-pane>
      </el-tabs>
    </div>


    <div class="fittings-list">
      <div class="fittings-list-item" v-for="(fitting, key) in fittings" :key="key">
        <div class="container" @click="showFitting=true">
          <el-row class="container-item">
            <el-col :span="9" class="container-item-img">
              <img :src="fitting.img"/>
            </el-col>
            <el-col :span="15" class="container-item-content">
              <span class="buyer">采购</span>
              <p class="title">{{fitting.name}}</p>
              <div class="remark">
                <p v-for="(txt, idx) in fitting.special" :key="idx">{{txt}}</p>
              </div>
              <p class="price">
                <span class="label">零售价:</span>
                <span class="value">¥ {{(fitting.unitPrice / 100) | F1000}}</span>
              </p>
              <p class="price">
                <span class="label">批发价 / 起批量:</span>
                <span class="value">¥ {{(fitting.batchPrice / 100) | F1000}} / {{fitting.batchLow}}</span>
              </p>

            </el-col>
          </el-row>
          <el-row class="container-info">
            <el-col :span="12" class="info">
              <span class="info-label">采购总量</span>
              <span class="info-value">{{fitting.total}}</span>
            </el-col>
            <el-col :span="12" class="info">
              <span class="info-label">库存总量</span>
              <span class="info-value">{{fitting.rest}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>


    <transition name="fade-transform" mode="out-in" >
      <div class="fittings-modal" v-show="showFitting">
        <div class="container">
          <el-row class="container-basic">
            <el-col :span="10" class="container-basic-img">
              <img :src="fitting.cover" class="cover"/>
              <el-row>
                <el-col :span="8" v-for="(img, idx) in fitting.imgs" :key="idx">
                  <img :src="img" class="thumb"/>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="14" class="container-basic-content">
              <p class="title">
                <span class="title-name">{{fitting.name}}</span>
                <span class="title-buyer">立即订购</span>
              </p>
              <p class="info">
                <span class="info-label">仓储存量</span>
                <span class="info-value">{{fitting.rest}}</span>
              </p>
              <p class="info">
                <span class="info-label">已有总数</span>
                <span class="info-value">{{fitting.total}}</span>
              </p>
              <el-row>
                <el-col :span="8" class="table">
                  <p class="table-label">零售价</p>
                  <p class="table-name">{{fitting.unitPrice}}</p>
                </el-col>
                <el-col :span="8" class="table">
                  <p class="table-label">批发价</p>
                  <p class="table-name">{{fitting.batchPrice}}</p>
                </el-col>
                <el-col :span="8" class="table">
                  <p class="table-label">起批量</p>
                  <p class="table-name">{{fitting.batchLow}}</p>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <div class="container-content">
            <p class="container-content-title">参数信息</p>
            <div class="container-content-item">
              <p  v-for="(special, idx) in fitting.specials" :key="idx">{{special}}</p>
            </div>
          </div>

          <div class="container-button">
            <el-button type="primary" @click="showFitting = false">确认</el-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        showFitting: false,
        tabs: [
          "易耗备件",
          "维修备件",
          "冰箱",
          "温杯架",
          "灯带",
          "读卡器",
        ],
        fitting: {
          cover: 'http://img.dr-coffee.cn/drink/default/espresso.png',
          imgs: [
            'http://img.dr-coffee.cn/drink/default/espresso.png',
            'http://img.dr-coffee.cn/drink/default/espresso.png',
            'http://img.dr-coffee.cn/drink/default/espresso.png',
          ],
          name: '电磁冰箱',
          unitPrice: 100,
          batchPrice: 80,
          batchLow: 100,
          total: 100,
          rest: 100,
          specials: [
            "温度范围：2～8度",
            "快速恒温",
            "额定功率",
          ],
        },
        fittings: [
          {
            img: 'http://img.dr-coffee.cn/drink/default/espresso.png',
            name: '电磁冰箱',
            unitPrice: 100,
            batchPrice: 80,
            batchLow: 100,
            total: 100,
            rest: 100,
            special: [
              "温度范围：2～8度",
              "快速恒温"
            ],
          },
          {
            img: 'http://img.dr-coffee.cn/drink/default/espresso.png',
            name: '电磁冰箱',
            unitPrice: 100,
            batchPrice: 80,
            batchLow: 100,
            total: 100,
            rest: 100,
            special: [
              "温度范围：2～8度",
              "快速恒温"
            ],
          },
          {
            img: 'http://img.dr-coffee.cn/drink/default/espresso.png',
            name: '电磁冰箱',
            unitPrice: 100,
            batchPrice: 80,
            batchLow: 100,
            total: 100,
            rest: 100,
            special: [
              "温度范围：2～8度",
              "快速恒温"
            ],
          },
          {
            img: 'http://img.dr-coffee.cn/drink/default/espresso.png',
            name: '电磁冰箱',
            unitPrice: 100,
            batchPrice: 80,
            batchLow: 100,
            total: 100,
            rest: 100,
            special: [
              "温度范围：2～8度",
              "快速恒温"
            ],
          },
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .fittings {
    overflow-y: scroll;
    width: 100%;

    &-filter {
      padding: $container-padding;
      padding-bottom: 0;
      background-color: $color-white;

      &-title {
        @include page-title();
      }

      &-select {
        width: 95%;
        margin-left: 5%;
      }
      ::v-deep .el-tabs__header{
        margin: 0;
        margin-top: $small-space;
        .el-tabs__nav-wrap:after{
          height: 0;
        }
      }

    }

    &-list {
      padding: $container-padding;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow-y: scroll;

      &-item {
        width: 32%;
        margin-bottom: $large-space;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .container {
          position: relative;
          padding: $middle-space;
          background-color: $color-white;
          cursor: pointer;

          &-item {
            &-img {
              img {
                width: 100%;
                max-width: 132px;
              }
            }

            &-content {
              position: relative;
              padding-left: $small-space;

              .buyer {
                position: absolute;
                top: 0;
                right: 0;
                border: 1px solid $theme-color;
                padding: 0 2px;
                color: $theme-color;
                border-radius: 2px;
              }

              .buyer:hover {
                font-weight: 500;
                color: $color-white;
                background-color: $theme-color;
              }

              .title {
                color: $color-black;
                @include font-medium();
                margin-bottom: $small-space;
              }

              .price {
                .label {
                  @include font-little();
                  margin-right: $small-space;
                  display: inline-block;
                  min-width: 88px;
                }

                .value {
                  color: $color-black;
                }


              }

              .remark {
                @include font-little();
                margin-bottom: $small-space;

                p {
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                }
              }
            }
          }

          &-info {
            margin-top: 4px;
            padding-top: $small-space;
            border-top: 1px solid $container-border-color;

            .info {
              span {
                display: inline-block;

              }

              &-label {
                width: 40%;
                text-align: center;
                @include font-little();
                //  background-color: $gray-5;
              }

              &-value {
                width: 60%;
                padding-left: $small-space;
                color: $color-black;
              }
            }
          }

        }

        .container:hover {
          box-shadow: $box-shadow;
        }
      }

      &-item:not(:nth-child(3n)) {
        margin-right: 2%;
      }
    }

    &-modal {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      background-color: rgba(0, 0, 0, .3);
      overflow: hidden;

      .container {
        padding: $container-padding;
        border-radius: $box-mini-radius;
        width: 64%;
        margin: auto;
        margin-top: 5%;
        background-color: $color-white;

        &-basic{
          padding-bottom: $middle-space;
          border-bottom: 1px solid $container-border-color;

          &-img {
            text-align: center;
            .thumb {
              width: 100%;
              max-width: 80px;
              cursor: pointer;
            }

            .thumb:hover{
              border: 1px solid $theme-color;
            }
            .cover {
              width: 100%;
              max-width: 160px;
            }
          }

          &-content{
            .title{
              margin-bottom: $middle-space;
              &-name{
                color: $color-black;
                @include  font-large-s();
              }

              &-buyer{
                color: $theme-color;
                margin-left: $small-space;
                border-radius: 2px;
                border: 1px solid $theme-color;
                padding: 0 2px;
                cursor: pointer;
              }
              &-buyer:hover{
                background-color: $theme-color;
                color: $color-white;
              }
            }
          }

          .info{
            margin-bottom: $small-space;
            span{
              display: inline-block;
            }

            &-label{
              @include font-little();
            }

            &-value{
              margin-left: $small-space;
              color: $color-black;
              width: 60%;
            }
          }

          .table{
            margin-top: $small-space;
            text-align: center;
            &-label{
              background-color: $gray-5;
              padding-left: $small-space;
              padding-top: 4px;
              padding-bottom: 4px;
            }

            &-name{
              padding-left: $small-space;
              margin: $small-space 0;
            }
          }
        }

        &-content{
          &-title{
            margin-top: $small-space;
            @include font-medium();
            font-weight: 500;
          }
          &-item{
            width: 100%;
            height: 240px;
            overflow-y: scroll;
           p{
             margin: $small-space;
           }
          }
        }

        &-button{
          margin-top: $small-space;
          text-align: center;
        }
      }
    }
  }

</style>
